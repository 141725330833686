.site-header {
  position: relative;
  width: $site-width;
  max-width: $site-max-width;
  margin: #{$spacer * 2} auto;
  text-align: center;
  z-index: 10;
}

.site-logo {
  max-width: 10em;
}
