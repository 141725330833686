body {
  font-size: $base-font-size;
  line-height: $line-height;
  font-family: $font-family;
  color: $text-color;

  @media only screen and (min-width: $breakpoint-large) {
    font-size: #{$base-font-size * 1.125};
  }
}

p,
ul,
ol {
  margin: 0 0 $spacer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0 0 $spacer;
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
}

h1 {
  font-size: 1.25rem;
}

h2 {
  font-size: 1.1rem;
}

h3,
h4,
h5,
h6 {
  font-size: $base-font-size;
}

img {
  width: auto;
  max-width: 100%;
}

a {
  color: $link-color;
  text-decoration: $link-text-decoration;

  &:hover,
  &:focus {
    color: $link-color-hover;
    text-decoration: $link-text-decoration-hover;
  }
}
