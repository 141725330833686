.cookie-banner {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  color: #eee;

  .container {
    margin-top: $spacer;
    margin-bottom: $spacer;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  p {
    margin-bottom: #{$spacer / 2};
  }

  input[type='checkbox'] {
    margin-right: 0.75rem;
  }

  a {
    color: #ccc;
  }
}
