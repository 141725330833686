@font-face {
  font-family: 'Bembo Std';
  src: url('../../fonts/BemboStd-Bold.eot');
  src: url('../../fonts/BemboStd-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/BemboStd-Bold.woff2') format('woff2'),
    url('../../fonts/BemboStd-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Bembo';
  src: url('../../fonts/Bembo.eot');
  src: url('../../fonts/Bembo.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Bembo.woff2') format('woff2'),
    url('../../fonts/Bembo.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
