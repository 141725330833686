// Settings
@import "settings/variables";

// Tools
@import "tools/mixins";

// Generic
@import "generic/reset";

// Elements
@import "elements/fonts";
@import "elements/typography";

// Objects
@import "objects/layout";
@import "objects/icons";

// Components
@import "components/header";
@import "components/navigation";
@import "components/navigation-list";
@import "components/address-block";
@import "components/cookie-banner";

// Utilities
@import "utilities/utilities";

/* Booking widget overrides */
#rd-widget-root {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

#rd-widget-root .rd-widget-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.main-content {
  margin-bottom: #{$spacer * 2};
}

.has-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  padding-bottom: 65%;
}

@media only screen and (max-width: #{$breakpoint - 1}) {
  .nav-open {
    &:after {
      content: "";
      position: fixed;
      background-color: #fff;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity #{$transition-speed} #{$transition-timing};
      opacity: 1;
      z-index: 5;
    }
  }

  .hide-overlay {
    &:after {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.widget-container {
  display: none;

  iframe {
    width: 100%;
  }
}
