$site-width: 100% !default;
$site-max-width: 58.75em !default;

$spacer: 1.5rem !default;
$line-height: 1.5 !default;
$base-font-size: 1rem !default;
$font-family: 'Bembo', serif !default;
$text-color: #444 !default;

$letter-spacing: 0.15rem !default;

$link-color: #444 !default;
$link-color-hover: #444 !default;
$link-text-decoration: underline !default;
$link-text-decoration-hover: none !default;

$grid-gutter: 1em !default;

$breakpoint: 700px !default;
$breakpoint-large: 1280px !default;

$transition-speed: 0.3s !default;
$transition-timing: ease-in-out !default;
