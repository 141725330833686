@media only screen and (max-width: #{$breakpoint - 1}) {
  .nav-wrapper {
    transition: opacity #{$transition-speed} #{$transition-timing};
    opacity: 1;
    position: absolute;
    top: 12.5em;
    left: 0;
    right: 0;
    background-color: #fff;
    padding-left: $spacer;
    z-index: 10;
  }

  .nav-hidden {
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
  }
}

.nav-list {
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
  margin-bottom: #{$spacer * 2};
  list-style: none;
  padding-left: 0;
}

.nav-list__item {
  margin-bottom: #{$spacer / 2};
}

.nav-list__link {
  color: $text-color;
  text-decoration: none;
}

@media only screen and (min-width: $breakpoint) {
  .nav-wrapper {
    height: auto !important;
  }
}
