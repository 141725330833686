.container {
  @include clearfix;
  max-width: $site-max-width;
  margin: #{$spacer * 2} $spacer;

  @media only screen and (min-width: $breakpoint) {
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacer;
    padding-right: $spacer;
  }
}

@media only screen and (min-width: $breakpoint) {
  .grid-row {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: $grid-gutter;
    grid-row-gap: 0;
  }

  @for $i from 1 through 12 {
    .grid-col-#{$i} {
      grid-column: span #{$i};
    }
  }
}

.gallery-image {
  display: block;
  margin-bottom: ($grid-gutter * 2);
}

@media only screen and (min-width: $breakpoint) {
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ($grid-gutter * 2);
    grid-row-gap: ($grid-gutter * 2);
  }

  .gallery-image {
    margin-bottom: 0;
  }
}

.lightbox-image {
  display: none;

  &.is-open {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 10;

    .modal-inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 92%;
      height: auto;
      max-width: 800px;
      text-align: right;
    }
  }
}
