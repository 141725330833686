.navigation {
  margin-bottom: #{$spacer * 2};
}

.nav-toggle {
  position: absolute;
  top: $spacer;
  left: $spacer;
  width: $spacer;
  height: 15px;
  background-color: #fff;
  border: 0;
  padding: 0;
  z-index: 10;
}

.nav-button-bar {
  width: 100%;
  height: 2px;
  background-color: $text-color;
  position: absolute;

  &:nth-child(1) {
    top: 0;
  }

  &:nth-child(2) {
    top: 50%;
    transform: translateY(-1px);
  }

  &:nth-child(3) {
    top: 50%;
    transform: translateY(-1px);
  }

  &:nth-child(4) {
    top: 100%;
    transform: translateY(-1px);
  }

  .nav-toggle--open & {
    &:nth-child(1),
    &:nth-child(4) {
      display: none;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
}
