.hide {
  display: none !important;
}

@media only screen and (min-width: $breakpoint) {
  .hide--large {
    display: none !important;
  }
}

@media only screen and (max-width: #{$breakpoint - 1}) {
  .hide--small {
    display: none !important;
  }
}

.small-text {
  font-size: 0.875rem;
}

.link {
  background: none;
  border: none;
  font: inherit;
  font-weight: bold;
}
