.social-icons {
  list-style: none;
  padding-left: 0;

  li {
    display: inline-block;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.social-icon {
  width: #{$base-font-size * 1.5};
  height: #{$base-font-size * 1.5};
}
